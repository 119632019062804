import React, { useEffect, useState } from "react";
import './HomePage.css'
import { Card, NextUIProvider } from "@nextui-org/react";
import { CardBody, CardTitle, ThemeProvider } from "react-bootstrap";
import { S3ClientService } from "../../Utils/S3Client";
import { DynamoDBClientService } from "../../Utils/DynamoDBClientService";

interface PortfolioItem {
    id: string;
    name: string;
    classification: [string];
    details: [string];
    end: string;
    institutionName: string;
    links: [string];
    place: string;
    start: string;
    title: [string];
    types: [string];
}

const HomePage:React.FC = () => {
    const [imageUrl, setImageUrl] = useState<string>("");
    const [portfolioData, setPortfolioData] = useState<PortfolioItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            const dynamoDBService = new DynamoDBClientService("us-east-1", "AKIAWDZW3ALMSLEEI3NB", "zbNXxgvQA2etm1AJ6d0+fhMpr5LkfrY0FEGn9z4y"); // Use secure methods for credentials
            const data = await dynamoDBService.getAllItems('jia-portfolio-details'); // Fetching data from DynamoDB
            setPortfolioData(data);
            setIsLoading(false);
        };
    
        fetchData();
      }, []);
    
    useEffect(() => {
        const fetchImageUrl = async () => {
            const region = process.env.REACT_APP_AWS_REGION!;;
            const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID!;
            const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;
            const bucketName = process.env.REACT_APP_BUCKET_NAME!;
            const objectKey = "IMG_3944.jpeg";

            const s3Service = new S3ClientService("us-east-1", "AKIAWDZW3ALMSLEEI3NB", "zbNXxgvQA2etm1AJ6d0+fhMpr5LkfrY0FEGn9z4y");

            try {
                const url = await s3Service.getSignedUrl("portfolios-profile-pictures", objectKey);
                setImageUrl(url);
            } catch (error) {
                console.error("Error fetching signed URL:", error);
            }
        };

        fetchImageUrl();
    }, []);

    useEffect(() => {

    })

    return (
        <div className="container-fluid">
            <div className="row" style={{width: '1000px'}}>
                <div className="image col-sm">
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="From S3"
                        />
                        ) : (
                        <p>Loading...</p>
                    )}
                </div>
                <div className="col-sm">
                    <h2 className="text-3xl font-bold mb-4">This is Jia!!!</h2>
                        <p className="text-lg text-gray-700">
                        Currently a student at the Northwood high school in Irvine, CA - Class of 2026. 
                        I am passionate about everything engineering. I find problems and fix them.
                        Love physics, math, software or engineering tools to build or fix things.
                        I have deep interest in artificial intelligence, space technologies.
                    </p>
                </div>
            </div>
            <div className="row project-list">
                {isLoading ? (
                    <p>Loading portfolio...</p>
                ) : (
                    <ul>
                    {portfolioData.map((item, index) => (
                        <ul key={index}>
                            <div className="card project-card">
                                <div className="card-body">
                                    <h3 className="card-text">{item.name}</h3>
                                    {
                                        item.title.map((item, index) => (
                                            <h5 key={index}>{item}</h5>
                                        ))
                                    }
                                    <p className="card-text">{item.institutionName}</p>
                                    <p className="card-text">{item.place}</p>
                                    <p className="card-text">{item.start} - {item.end}</p>
                                    {
                                        item.classification.map((item, index) => (
                                            <ul key={index}>{item}</ul>
                                        ))
                                    }
                                    {
                                        item.details.map((item, index) => (
                                            <ul key={index}>{item}</ul>
                                        ))
                                    }
                                    {
                                        item.title.map((item, index) => (
                                            <ul key={index}>{item}</ul>
                                        ))
                                    }
                                    {item.links ? (
                                        item.links.map((item, index) => (
                                            <ul key={index}>{item}</ul>
                                        ))
                                    ): (<a></a>)
                                    }
                                </div>
                            </div>
                        </ul>
                    ))}
                    </ul>
                )}
            </div>
        </div> 
    )
}

export default HomePage;