import React, { createContext, useEffect, useState } from "react";
import logo from "./jia-logo.svg";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";
import Projects from "./Pages/Projects/Projects";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Component/NavBar";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export const ThemeContext = createContext(lightTheme);

function App() {
  const [theme, setTheme] = useState(lightTheme);

  const changeTheme = () => {
    if (theme === darkTheme) {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header onClick={changeTheme}/>
      <Router>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/projects" Component={Projects} />
        </Routes>
      </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
