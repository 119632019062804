// src/services/S3ClientService.ts
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export class S3ClientService {
  private s3Client: S3Client;

  constructor(region: string, accessKeyId: string, secretAccessKey: string) {
    this.s3Client = new S3Client({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
      },
    });
  }

  public async getSignedUrl(bucketName: string, objectKey: string, expiresIn: number = 3600): Promise<string> {
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: objectKey,
    });

    return await getSignedUrl(this.s3Client, command, { expiresIn });
  }
}
