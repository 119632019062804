// src/services/DynamoDBClientService.ts
import { DynamoDBClient, ScanCommand, QueryCommand, GetItemCommand, PutItemCommand } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { marshall, unmarshall } from "@aws-sdk/util-dynamodb";

export class DynamoDBClientService {
  private docClient: DynamoDBDocumentClient;

  constructor(region: string, accessKeyId: string, secretAccessKey: string) {
    const dynamoDBClient = new DynamoDBClient({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
      },
    });

    this.docClient = DynamoDBDocumentClient.from(dynamoDBClient);
  }

  // Fetch all items from a table (using scan)
  public async getAllItems(tableName: string): Promise<any[]> {
    try {
      const command = new ScanCommand({ TableName: tableName });
      const result = await this.docClient.send(command);
      return result.Items ? result.Items.map((item) => unmarshall(item)) : [];
    } catch (error) {
      console.error('Error fetching items from DynamoDB:', error);
      return [];
    }
  }

  // Fetch specific items using a query (based on partition key)
  public async queryItems(tableName: string, keyConditionExpression: string, expressionAttributeValues: Record<string, any>): Promise<any[]> {
    try {
      const command = new QueryCommand({
        TableName: tableName,
        KeyConditionExpression: keyConditionExpression,
        ExpressionAttributeValues: marshall(expressionAttributeValues),
      });
      const result = await this.docClient.send(command);
      return result.Items ? result.Items.map((item) => unmarshall(item)) : [];
    } catch (error) {
      console.error('Error querying items from DynamoDB:', error);
      return [];
    }
  }

  // Fetch a single item by its primary key (partition key and optional sort key)
  public async getItem(tableName: string, key: Record<string, any>): Promise<any | null> {
    try {
      const command = new GetItemCommand({
        TableName: tableName,
        Key: marshall(key),
      });
      const result = await this.docClient.send(command);
      return result.Item ? unmarshall(result.Item) : null;
    } catch (error) {
      console.error('Error fetching item from DynamoDB:', error);
      return null;
    }
  }

  // Insert an item into the table
  public async putItem(tableName: string, item: Record<string, any>): Promise<boolean> {
    try {
      const command = new PutItemCommand({
        TableName: tableName,
        Item: marshall(item),
      });
      await this.docClient.send(command);
      return true;
    } catch (error) {
      console.error('Error inserting item into DynamoDB:', error);
      return false;
    }
  }
}
