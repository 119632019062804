import { NavbarContent, NavbarItem } from "@nextui-org/react";
import React, { useContext } from "react";
import { Navbar, NavItem, NavDropdown, Nav, Form, FormControl, Button } from 'react-bootstrap';
import App, { ThemeContext } from "../App";
import { createTheme } from "@mui/material";

const NavBar = ({onClick}: {onClick:any}) => {
    const [themeBtnTxt, setThemeBtnTxt] = React.useState('Light Mode');

    function handleThemeBtnClick(){
        if (themeBtnTxt === 'Light Mode'){
            setThemeBtnTxt('Dark Mode');
            onClick();
        } else {
            setThemeBtnTxt('Light Mode');
            onClick();
        }
        
    }

    return (
        <Navbar bg='light' expand='lg'>
        <Navbar.Brand href="#home">
        <img src="JD.png" 
            width="50" height="50" 
            alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className='mr-auto'>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
        </Nav>
        </Navbar.Collapse>
        <Nav className="mr-auto" style={{paddingRight: '20px'}}>
            {themeBtnTxt === 'Light Mode' ? (
                <Button className="btn btn-dark" onClick={handleThemeBtnClick}>{themeBtnTxt}</Button>
            ) : (
                <Button className="btn btn-light" onClick={handleThemeBtnClick}>{themeBtnTxt}</Button>
            )}
        </Nav>
        </Navbar>
    )
}

export default NavBar;



